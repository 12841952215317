export const formRules = {
    cName: [{ required: true, message: '请输入发车时刻表名称', trigger: 'blur' }],
    routeId: [{ required: true, message: '请输入选择线路', trigger: 'change' }],
}
export const caseRules = {
    sSiteId: [{ required: true, message: '请输入选择起始站', trigger: 'change' }],
    eSiteId: [{ required: true, message: '请输入选择终点站', trigger: 'change' }],
    schemeT: [{ required: true, message: '请输入选择终点站', trigger: 'change' }],
}
export const caseRulesSub = {
    sTime: [{ required: true, message: '请输入选择首班时间', trigger: 'change' }],
    times: [{ required: true, message: '请输入班次总数', trigger: 'blur' },
        { type: 'number', message: '必须为数字值', trigger: 'blur' }
    ],
    interval: [{ required: true, message: '请输入发班次间隔', trigger: 'blur' },
        { type: 'number', message: '必须为数字值', trigger: 'blur' }
    ],
}
export const pickerOptions = {
    selectableRange: '00:00:00 - 23:59:59',
    format: 'HH:mm'
}
export const typeStrList = ['新增', '编辑', '查看']

export const caseColorList = window.config.chartColor
export const caseNameList = []
for (let i = 1; i <= caseColorList.length; i++) {
    caseNameList.push('方案 ' + i)
}
