<template>
    <jy-dialog title="方案基础信息" :visible.sync="dialogVisible" custom-class="dialog_form" :width="'400px'">
        <el-form :model="shift" ref="dialogForm" label-width="110px">
            <el-form-item label="发车时间">
                <el-time-picker v-model="shift.dTime" :format="pickerOptions.format" :value-format="pickerOptions.format" :picker-options="pickerOptions" placeholder="首班时间"></el-time-picker>
            </el-form-item>
            <el-form-item label="发车方案">
                <el-popover trigger="click">
                    <ul>
                        <li class="case_sty" :style="`background:${one.schemeC}`" v-for="(one,k) in caseList" :key="k" v-on:click="selectCase(one,k)">{{one.schemeNa}}</li>
                    </ul>
                    <span slot="reference" class="case_sty" :style="`background:${caseObj.schemeC}`">
                        {{caseObj.schemeNa}}
                    </span>
                </el-popover>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button v-on:click="dialogVisible=false">取消</el-button>
            <el-button type="primary" v-on:click="confimFun">确定</el-button>
        </template>
    </jy-dialog>
</template>
<script>
import { pickerOptions } from './addUpdateSub/initData.js'
export default {
    data() {
        return {
            pickerOptions,
            dialogVisible: false,
            popVisible: false,
            caseKey: 0
        }
    },
    props: {
        caseList: {
            type: Array,
            default: () => []
        },
        shift: {
            default: () => {}
        },
        isShow: {
            type: Boolean
        }
    },
    computed: {
        caseObj: function() {
            return this.caseList[this.caseKey] || {}
        }
    },
    model: {
        prop: 'isShow',
        event: 'setIsShow'
    },
    watch: {
        isShow: function(val) {
            if (val != this.dialogVisible) {
                this.dialogVisible = val
            }
        },
        'shift.schemeNa': function(val) {
            this.caseList.forEach((one, key) => {
                if (one.schemeNa == val) {
                    this.caseKey = key
                }
            })
        },
        dialogVisible: function(val) {
            if (val != this.isShow) {
                this.$emit('setIsShow', val)
            }
        }
    },
    methods: {
        confimFun() {
            this.dialogVisible=false
            this.shift.schemeNa = this.caseObj.schemeNa
            this.shift.schemeC = this.caseObj.schemeC
            this.$emit('confimFun')
        },
        selectCase(one,k){
            this.caseKey = k
            Object.keys(one).forEach(key=>{
                this.shift[key]=one[key]
            })
        }
    }
}

</script>
<style lang="scss" scoped>
.case_sty {
    display: inline-block;
    padding: 0px 14px;
    border-radius: 2px;
    color: #fff;
    line-height: 36px;
    font-size: 14px;
    @extend .hand;
}

ul {
    li {
        &.case_sty {
            display: block;
        }
    }
}

</style>
