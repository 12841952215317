export class Case {
    constructor(schemeNa, schemeC, siteList) {
        this.id = new Date().getTime() + Math.stochastic(100, 1000)
        // this.schemeId = ''
        this.schemeNa = schemeNa
        this.schemeT = ''
        this.schemeC = schemeC || ''
        this.sSiteId = ''
        this.sSiteNa = ''
        this.eSiteId = ''
        this.eSiteNa = ''
        this.sTime = ''
        this.times = ''
        this.interval = ''
        this.siteList = siteList || []
    }
    setSName() {
        this.siteList.forEach(site => {
            if (site.siteId == this.sSiteId) {
                this.sSiteNa = site.cName
            }
        })
    }
    setEName() {
        this.siteList.forEach(site => {
            if (site.siteId == this.eSiteId) {
                this.eSiteNa = site.cName
            }
        })
    }
    setSiteList(list) {
        this.sSiteNa = ''
        this.eSiteNa = ''
        this.siteList = list || []
        this.siteList.forEach(one => {
            if (one.sSiteId == this.sSiteId) {
                this.sSiteNa = one.sSiteNa
            }
            if (one.eSiteId == this.eSiteId) {
                this.eSiteNa = one.eSiteNa
            }
        })

        if (!this.sSiteNa) {
            this.sSiteId = ''
        }
        if (!this.eSiteNa) {
            this.eSiteId = ''
        }

    }
}

export default Case
