<template>
    <div>
        <jy-dialog type="table" :title="title" :visible.sync="dialogVisible" custom-class="dialog_form" :width="'1000px'">
            <div v-show="step==0">
                <el-form :model="formData" ref="dialogForm" label-width="180px" :rules="formRules" :disabled="type==2">
                    <el-form-item label="发车时刻表名称" prop="cName">
                        <el-input v-model="formData.cName"></el-input>
                    </el-form-item>
                    <el-form-item label="线路" prop="routeId">
                        <el-select v-model="formData.routeId">
                            <el-option v-for="one in routeList" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label-width="0">
                        <div class="case_box mg" v-for="(item,k) in caseList" v-bind:key="item.schemeNa">
                            <div class="left">
                                <span class="case_sty" :style="`background:${item.schemeC}`">
                                    {{item.schemeNa}}
                                </span>
                            </div>
                            <div class="center">
                                <el-form label-width="70px" ref="case" :rules="caseRules" :model="item" :disabled="type==2">
                                    <el-row>
                                        <el-col :span="9">
                                            <el-form-item label="起始站" prop="sSiteId">
                                                <el-select v-model="item.sSiteId" @change="onChange(item,'setSName')">
                                                    <el-option v-for="one in siteList" v-bind:key="one.siteId" :label="one.cName" :value="one.siteId"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="9">
                                            <el-form-item label="终点站" prop="eSiteId">
                                                <el-select v-model="item.eSiteId" @change="onChange(item,'setEName')">
                                                    <el-option v-for="one in siteList" v-bind:key="one.siteId" :label="one.cName" :value="one.siteId"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                        <el-col :span="6">
                                            <el-form-item label="方向" prop="schemeT">
                                                <el-select v-model="item.schemeT" @change="onChange(item,'setEName')">
                                                    <el-option v-for="one in schemets" v-bind:key="one.value" :label="one.label" :value="one.value"></el-option>
                                                </el-select>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-form>
                            </div>
                            <div class="right">
                                <el-button type="primary" v-on:click="addCaseFun">增加</el-button>
                                <el-button v-if="k>0" v-on:click="delCaseFun(k)">删除</el-button>
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item label="备注" prop="remark">
                        <el-input v-model="formData.remark" type="textarea" :rows="3"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div v-show="step==1">
                <div class="case_box" v-for="(item) in caseList" v-bind:key="item.schemeNa">
                    <div class="left">
                        <span class="case_sty" :style="`background:${item.schemeC}`">
                            {{item.schemeNa}}
                        </span>
                    </div>
                    <div class="center">
                        <el-form label-width="100px" ref="caseSub" :model="item" :rules="caseRulesSub" :disabled="type==2">
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="首班时间" prop="sTime">
                                        <el-time-picker v-model="item.sTime" :format="pickerOptions.format" :value-format="pickerOptions.format" :picker-options="pickerOptions" placeholder="首班时间">
                                        </el-time-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="班次总数" prop="times">
                                        <el-input v-model.number="item.times"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="班次间隔" prop="interval">
                                        <el-input v-model.number="item.interval">
                                            <template slot="append">分钟</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                    </div>
                </div>
            </div>
            <div v-show="step==2">
                <div>
                    <time-table :list="shifts" :canUpdate="type!=2" :operationList="operationList" @dragItem="dragItem" :isInit="isInit"></time-table>
                </div>
            </div>
            <template #footer>
                <div class="footer_box">
                    <el-button type="primary" v-on:click="addShiftFun" v-if="step==2 && type!=2">新增</el-button>
                    <el-button type="primary" v-on:click="pre" v-if="step>0">上一步</el-button>
                    <el-button v-on:click="dialogVisible=false">取消</el-button>
                    <el-button type="primary" v-on:click="next" v-if="step<2">下一步</el-button>
                    <el-button type="primary" v-on:click="submitFun" v-if="step==2 && type!=2">完成</el-button>
                </div>
            </template>
        </jy-dialog>
        <case-msg ref="caseMsg" @update="updateCaseMsg"></case-msg>
        <a-u-s v-model="showAUS" :caseList="caseList" :shift="shiftAlive" @confimFun="addUpdateShift"></a-u-s>
    </div>
</template>
<script>
import caseMsg from './caseMsg.vue'
import aUS from './addUpdateShift.vue'
import userIdWatch from '@/common/js/userIdWatch.mixin.js'
import * as initData from './addUpdateSub/initData.js'
import Case from './addUpdateSub/Case.js'
import Shift from './addUpdateSub/Shift.js'
import Detail from './addUpdateSub/Detail.js'
import timeTable from '@/components/pages/admin/common/timeTable/timeTable'
// import { getSchemeTs } from '@/common/js/wordbook.js'

export default {
    mixins: [userIdWatch],
    data() {
        // let schemets = getSchemeTs()
        return {
            ...initData,
            step: 0,
            schemets:[],
            dialogVisible: false,
            showAUS: false,
            type: 0, //0-add,1-update,2-view
            formData: {
                dayTimeTId: '',
                cName: '',
                routeId: '',
                remark: '',
            },
            rType:'',
            routeList: [],
            siteList: [],
            caseList: [],
            shifts: [],
            shiftAlive:{},
            operationList:[],
            isInit:true,
        }
    },
    computed: {
        title: function() {
            let str = '日发车时刻表'
            return this.typeStrList[this.type] + str
        }
    },
    components: {
        caseMsg,
        timeTable,
        aUS
    },
    created() {
        this.seOperation()
        this.getRouteList()
        this.addWatchUserIdFun(this.getRouteList)
        this.addCaseFun()
    },
    watch: {
        'formData.routeId': async function(val) {
            if (val) {
                this.getRtype()
                await this.getSiteList()
                if (this.type > 0) {
                    this.getDetail()
                }
            } else {
                this.siteList = []
                this.rType = ''
            }
        },
        rType: function(val){
            if(val){
                if(val == '0'){
                    this.schemets = [
                        {
                            value: '1',
                            label: '上行'
                        },
                        {
                            value: '2',
                            label: '下行'
                        }
                    ]
                }else if(val == '1'){
                    this.schemets = [
                        {
                            value: '3',
                            label: '环形'
                        }
                    ]
                }
            }else{
                this.schemets = []
            }
            if(this.type == 0){
                this.caseList.forEach(item=>{
                    if(!this.schemets.some(one=>one.value == item.schemeT)){
                        item.schemeT = ''
                    }
                })
            }
        },
    },
    methods: {
        seOperation(){
            this.operationList = [
                {
                    name: '编辑',
                    fun: (item) => {
                        this.update(item)
                    },
                },
                {
                    name: '复制',
                    fun: (item) => {
                        this.copy(item)
                    },
                },
                {
                    name: '删除',
                    fun: (item) => {
                        this.del(item)
                    },
                },
            ]
        },
        async init(option, type) {
            if (!type) {
                this.type = 0
                this.step = 0
            } else {
                this.type = type
            }
            this.isInit = true
            this.$nextTick(() => {
                this.reset()
                if (type > 0) {
                    this.formData.cName = option.cname
                    this.formData.routeId = option.routeId
                    this.formData.dayTimeTId = option.dayTimeTId
                    this.formData.remark = option.remark
                    this.step = 2
                    if (this.siteList.length) {
                        this.getDetail()
                    }
                }
            })
            this.dialogVisible = true
        },
        reset() {
            let refs = this.$refs
            Object.keys(refs).forEach(key => {
                if (refs[key] && refs[key].resetFields) {
                    refs[key].resetFields()
                }
                if (refs[key] instanceof Array) {
                    refs[key].forEach(one => {
                        one.resetFields()
                    })
                }
            })
            this.caseList = []
            this.shifts = []
            this.addCaseFun()
        },
        getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            this.$http.post(url).then(({ detail }) => {
                this.routeList = detail
            })
        },
        getRtype(){
            this.routeList.forEach(item=>{
                if(item.routeId == this.formData.routeId){
                    this.rType = item.rType
                }
            })
        },
        async getSiteList() {
            let url = '/site/info/queryByRouteId'
            let params = {
                routeId: this.formData.routeId
            }
            await this.$http.post(url, params).then(({ detail }) => {
                let list = detail.map(item=>{
                    return {
                        ...item,
                        ...item.routeSite,
                        ...item.siteInfo,
                    }
                })
                this.siteList = list
                this.caseList.forEach(caseObj => {
                    caseObj.setSiteList(list)
                })
            })
        },
        getDetail() {
            let url = '/day/timet/queryDetail'
            let params = {
                dayTimeTId: this.formData.dayTimeTId
            }
            this.$http.post(url, params).then(({ detail }) => {
                let dt = new Detail()
                dt.siteList = this.siteList
                dt.setShifts(detail)
                this.caseList = dt.getCaseListByShifts()
                console.log(this.caseList)
                this.shifts = dt.shifts
            })
        },
        submitFun() {
            let url = '/day/timet/add'
            let newNaList = []
            let newList = []
            this.shifts.forEach(item=>{
                if(!newNaList.includes(item.schemeNa)){
                    newNaList.push(item.schemeNa)
                }
            })
            newNaList.forEach(one=>{
                newList.push(this.shifts.filter(item=>item.schemeNa==one))
            })
            let params = {
                ...this.formData,
                smShiftList: newList
            }
            if (this.type == 1) {
                url = '/day/timet/update'
            } else {
                delete params.dayTimeTId
            }
            this.$http.post(url, params, { isRequestParam: false }).then(res => {
                this.$message({
                    type: 'success',
                    message: res.msg,
                })
                this.$emit('updateList')
                this.dialogVisible = false
            })
        },
        addCaseFun() {
            let key = this.caseList.length
            let name = this.caseNameList[key]
            let color = this.caseColorList[key]
            console.log(color)
            let caseObj = new Case(name, color, this.siteList)
            this.caseList.push(caseObj)
        },
        delCaseFun(key) {
            this.caseList.splice(key, 1)
        },
        addShiftFun() {
            this.shiftAlive = new Shift()
            this.showAUS = true
        },
        addUpdateShift(){
            if(this.shiftAlive.shiftId){
                let k = this.shifts.findIndex(one=>one.shiftId == this.shiftAlive.shiftId)
                this.shifts.splice(k,1,this.shiftAlive)
                this.$message({
                    type: 'success',
                    message: '修改成功',
                })
            }else{
                this.shiftAlive.shiftId = this.$util.randomString()
                this.shifts.push(this.shiftAlive)
                this.$message({
                    type: 'success',
                    message: '新增成功',
                })
            }
            console.log(this.shifts)
        },
        pre() {
            this.step--
        },
        next() {
            this.isInit = true
            let canNext = true
            switch (this.step) {
                case 0:
                    this.$refs.dialogForm.validate(v => {
                        if (!v) {
                            canNext = false
                        }
                    })
                    var caseForm = this.$refs.case
                    if (caseForm instanceof Array) {
                        caseForm.forEach(one => {
                            one.validate(v => {
                                if (!v) {
                                    canNext = false
                                }
                            })
                        })
                    } else {
                        caseForm.validate(v => {
                            if (!v) {
                                canNext = false
                            }
                        })
                    }
                    break;
                case 1:
                    var caseSubForm = this.$refs.caseSub
                    if (caseSubForm instanceof Array) {
                        caseSubForm.forEach(one => {
                            one.validate(v => {
                                if (!v) {
                                    canNext = false
                                }
                            })
                        })
                    } else {
                        caseSubForm.validate(v => {
                            if (!v) {
                                canNext = false
                            }
                        })
                    }
                    break;
            }
            if (canNext) {
                if (this.step == 1 && this.type != 2) {
                    let detail = new Detail()
                    detail.setCaseList(this.caseList)
                    this.shifts = detail.getShiftsByCase()
                }
                this.step++
            }
        },
        caseMsgFun(item) {
            if (this.type != 2) {
                this.$refs.caseMsg.init(item)
            }
        },
        updateCaseMsg(item) {
            this.caseList.forEach(one => {
                if (one.id == item.id) {
                    one.name = item.name
                    one.color = item.color
                }
            })
        },
        onChange(one, fun, detail) {
            console.log(detail)
            one[fun](detail)
        },
        update(item){
            this.isInit = false
            this.shiftAlive = new Shift()
            Object.keys(this.shiftAlive).forEach(prop=>{
                this.shiftAlive[prop] =  item[prop]
            })
            this.showAUS = true
        },
        copy(item){
            this.isInit = false
            let shift = new Shift()
            Object.keys(shift).forEach(prop=>{
                shift[prop] =  item[prop]
            })
            shift.shiftId = this.$util.randomString()
            this.shifts.push(shift)
            this.$message({
                type: 'success',
                message: '复制成功',
            })
        },
        del(item){
            this.isInit = false
            this.$confirm('是否删除该班次?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let k = this.shifts.findIndex(one=>one.shiftId == item.shiftId)
                    this.shifts.splice(k,1)
                    this.$message({
                        type: 'success',
                        message: '删除成功',
                    })
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        dragItem(item){
            this.isInit = false
            this.shiftAlive = new Shift()
            Object.keys(this.shiftAlive).forEach(prop=>{
                this.shiftAlive[prop] =  item[prop]
            })
            this.addUpdateShift()
        },
    }
}

</script>
<style lang="scss" scoped="dailyTimeTable_addUpdate">
.footer_box {
    text-align: center;
}

.case_box {
    display: flex;

    &+.mg {
        margin-top: 22px;
    }

    .left {
        width: 110px;

    }

    .case_sty {
        display: inline-block;
        padding: 0px 14px;
        border-radius: 2px;
        color: #fff;
        line-height: 36px;
        font-size: 14px;
    }

    .center {
        flex: 1;

        .el-date-editor {

            &.el-input,
            &.el-input__inner {
                width: auto;
            }
        }
    }

    .right {
        width: 160px;
        margin-left:20px;
    }
}

</style>
