import Case from './Case.js'
import Shift from './Shift.js'
import {caseNameList,caseColorList} from './initData.js'
import utils from '@/common/js/util.js'
export default class Detail {
    constructor() {
        this.caseList = []
        this.shifts = []
        this.siteList = []
    }
    setCaseList(cList) {
        this.caseList = []
        cList.forEach(caseObj => {
            this.addCase(caseObj)
        })
    }
    setShifts(shifts) {
        this.shifts = []
        shifts.forEach(shift => {
            this.addShift(shift)
        })
    }
    addCase(caseObj) {
        if (caseObj instanceof Case) {
            this.caseList.push(caseObj)
        }
    }
    addShift(shift) {
        if (shift instanceof Shift) {
            this.shifts.push(shift)
        } else {
            let newShift = new Shift()
            newShift.dTime = shift.dTime
            newShift.schemeNa = shift.schemeNa
            newShift.schemeT = shift.schemeT
            newShift.schemeC = shift.schemeC
            newShift.sSiteId = shift.sSiteId
            newShift.sSiteNa = shift.sSiteNa
            newShift.eSiteId = shift.eSiteId
            newShift.eSiteNa = shift.eSiteNa
            newShift.shiftId = shift.shiftId
            newShift.schemeId = shift.schemeId
            this.shifts.push(newShift)
        }
    }
    getShiftsByCase() {
        this.caseList.forEach((caseObj) => {
            this._getStartTimeList(caseObj).forEach(dTime => {
                let shift = new Shift()
                shift.dTime = dTime
                shift.schemeNa = caseObj.schemeNa
                shift.schemeT = caseObj.schemeT
                shift.schemeC = caseObj.schemeC
                shift.sSiteId = caseObj.sSiteId
                shift.sSiteNa = caseObj.sSiteNa
                shift.eSiteId = caseObj.eSiteId
                shift.eSiteNa = caseObj.eSiteNa
                shift.shiftId = utils.randomString()
                this.addShift(shift)
            })
        })
        return this.shifts
    }
    getCaseListByShifts() {
        let caseShiftList = this._getShiftsFromId()
        let caseList = []
        caseShiftList.forEach((li,k) => {
            li.sort((i1, i2) => {
                return this._getTime(i1.dTime) - this._getTime(i2.dTime)
            })
            li.forEach(item=>{
                item.schemeNa = caseNameList[k]
                item.schemeC = caseColorList[k]
            })
            let caseObj = new Case()
            caseObj.times = li.length
            caseObj.sTime = li[0].dTime
            caseObj.interval = Number(this._getMost(this._getIntevalList(li, 'dTime')))
            caseObj.sSiteId = this._getMost(li, 'sSiteId')
            caseObj.eSiteId = this._getMost(li, 'eSiteId')
            // caseObj.schemeNa = this._getMost(li, 'schemeNa')
            caseObj.schemeT = this._getMost(li, 'schemeT')
            // caseObj.schemeC = this._getMost(li, 'schemeC')
            caseObj.siteList = this.siteList
            caseList.push(caseObj)
        })
        caseList.forEach((item,k)=>{
            item.schemeNa = caseNameList[k]
            item.schemeC = caseColorList[k]
        })
        this.caseList = caseList
        return caseList
    }
    //获取发车时间列表 
    _getStartTimeList(caseObj) {
        let sTime = caseObj.sTime
        let times = caseObj.times
        let interval = caseObj.interval
        let list = []
        list.push(sTime)
        for (let i = 1; i < times; i++) {
            list.push(this._getNextTime(list[list.length - 1], interval))
        }
        return list
    }
    _getTime(t) {
        if (t.length == 'hh:mm'.length) {
            t += ':00'
        }
        let ymd = new Date().Format('yyyy-MM-dd ')
        return (new Date(ymd + t)).getTime()
    }
    _getNextTime(st, inte) {
        return (new Date(this._getTime(st) + inte * 60 * 1000)).Format('hh:mm')
    }
    _getShiftsFromId() {
        let newCaseList = []
        let list = []
        this.shifts.forEach(item=>{
            if(!list.includes(item.schemeId)){
                list.push(item.schemeId)
            }
        })
        list.forEach(item=>{
            newCaseList.push(this.shifts.filter(one => one.schemeId == item))
        })
        this.shifts.forEach(item=>{
            delete item.schemeId
        })
        console.log(newCaseList)
        return newCaseList
    }
    _getMost(li, prop) {
        let o = {}
        let v = ''
        if (prop === undefined) {
            li.forEach(item => {
                let key = String(item)
                o[key] ? o[key]++ : o[key] = 1
            })
        } else {
            li.forEach(item => {
                let key = item[prop]
                if (key !== undefined && key !== null) {
                    o[key] ? o[key]++ : (o[key] = 1)
                }
            })
        }
        Object.keys(o).forEach(k => {
            if (!v) {
                v = k
            } else {
                if (o[k] >= o[v]) {
                    v = k
                }
            }
        })
        return v
    }
    _getIntevalList(li, prop) {
        let list = li.map((one, k) => {
            if (k == 0) {
                return 0
            }
            return (this._getTime(one[prop]) - this._getTime(li[k - 1][prop])) / (60 * 1000)
        })
        return list
    }
}
