export default class Shift{
    constructor(){
        this.dTime = '' //计划发车时间
        this.schemeNa = '' //方案名称
        this.schemeT = '' //方案类型 1-上行 2-下行
        this.schemeC = '' //颜色
        this.sSiteId = '' //始站id
        this.sSiteNa = '' //始站名称
        this.eSiteId = '' //终站id
        this.eSiteNa = '' //终站名称
        this.shiftId = '' //班次id
        // this.schemeId = '' //方案id
    }
}