<template>
    <jy-dialog title="方案基础信息" :visible.sync="dialogVisible" custom-class="dialog_form" :width="'400px'">
        <el-form :model="formData" ref="dialogForm" label-width="110px">
            <el-form-item label="方案名称">
                <el-input v-model="formData.schemeNa"></el-input>
            </el-form-item>
            <el-form-item label="方案颜色">
                <el-color-picker v-model="formData.schemeC"></el-color-picker>
            </el-form-item>
        </el-form>
        <template #footer>
                <el-button v-on:click="dialogVisible=false">取消</el-button>
                <el-button type="primary" v-on:click="confimFun">确定</el-button>
        </template>
    </jy-dialog>
</template>
<script>
    export default{
        data(){
            return{
                dialogVisible:false,
                formData:{
                    schemeNa:'',
                    schemeC:'',
                }
            }
        },
        methods:{
            init(option){
                this.formData={
                    ...option
                }
                this.dialogVisible = true
            },
            confimFun(){
                this.$emit('update',this.formData)
                this.dialogVisible = false
            }
        }
    }
</script>