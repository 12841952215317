<template>
    <div>
        <jy-query label-width="100">
            <jy-query-item label="日时刻表名称">
                <el-input v-model="formData.cName"></el-input>
            </jy-query-item>
            <jy-query-item label="线路">
                <el-select v-model="formData.routeId">
                    <el-option v-for="one in routeList" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="创建人">
                <el-input v-model="formData.createNa"></el-input>
            </jy-query-item>
            <template #rightCol>
                <el-button type="primary" v-on:click="getList" v-if="btnexist('scheduleSchedulingDailyTimeTableList')">查询</el-button>
                <el-button type="primary" v-on:click="reset" v-if="btnexist('scheduleSchedulingDailyTimeTableReset')">重置</el-button>
                <el-row>
                    <el-button type="primary" v-on:click="addFun" v-if="btnexist('scheduleSchedulingDailyTimeTableInsert')">新增</el-button>
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="jyTable" :data="dataList">
            <jy-table-column label="序号" type="index" width="60"></jy-table-column>
            <jy-table-column label="日时刻表名称" prop="cname" min-width="170"></jy-table-column>
            <jy-table-column label="线路" prop="routeNa" min-width="170"></jy-table-column>
            <jy-table-column label="创建人" prop="createNa" min-width="100"></jy-table-column>
            <jy-table-column label="创建时间" prop="createT" min-width="170" :formatter="timeFormatter"></jy-table-column>
            <jy-table-column label="备注" prop="remark" min-width="170"></jy-table-column>
            <jy-operate :list="butList"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
        <add-update-dialog ref="addUpdate" @updateList="getList"></add-update-dialog>
    </div>
</template>
<script>
import { btnMixins } from '@/common/js/button.mixin'
import addUpdateDialog from '@/components/pages/admin/scheduling/dailyTimetable/addUpdate.vue'
export default {
    data() {
        return {
            routeList: [],
            dataList: [],
            butList: [],
            formData: {
                cName: '',
                createNa: '',
                routeId: ''
            },
            pageSize: 10,
            pageIndex: 1,
            total: 0,

            btnMenuId: 'scheduleSchedulingDailyTimeTable',
        }
    },
    created() {
        this.setButList()
        this.getRouteList()
        this.getList()
    },
    components: {
        addUpdateDialog
    },
    mixins: [btnMixins],
    methods: {
        setButList() {
            let list = [{
                name: '编辑',
                fun: (row) => {
                    this.$refs.addUpdate.init(row, 1)
                },
                isShow: () => { return this.btnexist('scheduleSchedulingDailyTimeTableUpdate') }
            }, {
                name: '查看',
                fun: (row) => {
                    this.$refs.addUpdate.init(row, 2)
                },
                isShow: () => { return this.btnexist('scheduleSchedulingDailyTimeTableView') }
            }, {
                name: '删除',
                fun: (row) => {
                    this.$confirm(`删除 ${row.cname}`,'',{
                        type:'warning'
                    }).then(()=>{
                        this.delFun(row.dayTimeTId)
                    })
                },
                isShow: () => { return this.btnexist('scheduleSchedulingDailyTimeTableDelete') }
            }]
            this.butList = list
        },
        timeFormatter(row, column, cellValue){
            if (column.property == 'createT') {
                return cellValue.split('.')[0]
            }
        },
        addFun() {
            this.$refs.addUpdate.init()
        },
        getRouteList() {
            let url = '/route/info/queryRouteInfoByOrgIds'
            this.$http.post(url).then(({ detail }) => {
                this.routeList = detail
            })
        },
        getList() {
            let url = '/day/timet/queryPage'
            let params = {
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
                ...this.formData
            }
            Object.keys(params).forEach(key=>{
                if (/^\s*$/.test(params[key])) {
                    delete params[key]
                }
            })
            this.$http.post(url, params).then(({detail}) => {
                this.total = detail.total
                this.dataList = detail.list
            })
        },
        delFun(id){
            let url = '/day/timet/del'
            let params = {
                dayTimeTId:id
            }
            this.$http.post(url,params).then(()=>{
                this.$message({
                    message:'删除成功',
                    type:'success'
                })
                this.getList()
            })
        },
        handleSizeChange(e) {
            this.pageSize = e;
            this.getList();
        },
        handleCurrentChange(e) {
            this.pageIndex = e;
            this.getList();
        },
        reset() {
            this.formData = {
                cName: '',
                routeId: ''
            }
            this.getList()
        }
    }
}

</script>
