import { mapGetters } from 'vuex'
export default {
    data() {
        return {
            watchUserIdFun:[]
        }
    },
    computed:{
        ...mapGetters(['userId'])
    },
    watch:{
        'userId':function(val){
            if (val) {
                this.watchUserIdFun.forEach(fun=>{
                    if (fun instanceof Function) {
                        fun()
                    }
                })
            }
        }
    },
    methods:{
        addWatchUserIdFun(fun){
            if (fun instanceof Function) {
                if (!this.watchUserIdFun.contains(fun)) {
                    this.watchUserIdFun.push(fun)
                }
            }
        }
    }
}
